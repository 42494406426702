/**
 * Page container meant to be used in containers
 * It's a very simple component with the aim of standardizing the css and page structure of (hopefully) most the pages
 */

import { PureComponent, ReactNode } from 'react'

import { ClassNames, SerializedStyles, css } from '@emotion/react'

import { ActiveLocation } from '@/redux/location/reducer'
import Banner from '@/stories/banner'
import Modal from '@/stories/modals'
import Navbar from '@/stories/navbar/component'
import { breakpoint, lightTheme } from '@/theme'

import { InferProps } from 'prop-types'

type PageContainerProps = {
  children: ReactNode
  fullHeight?: boolean
  hideBanner?: boolean
  hideModal?: boolean
  containerStyle: SerializedStyles
  location?: ActiveLocation
  navbarProps: InferProps<typeof Navbar.propTypes>
  noAlerts?: boolean
}
export default class PageContainer extends PureComponent<PageContainerProps> {
  static defaultProps = {
    containerStyle: '',
    location: {},
    navbarProps: {}
  }

  render() {
    const { children, containerStyle, fullHeight, hideBanner, hideModal, navbarProps } = this.props

    const containerClass = fullHeight ? fullHeightContainerCss : containerCss
    const navbarWrapperClass = fullHeight ? fullHeightNavbarWrapperCss : navbarWrapperCss
    const contentContainerClass = fullHeight ? fullHeightContentContainerCss : contentContainerCss

    return (
      <ClassNames>
        {({ css }) => (
          <div css={containerStyle} className={css(containerClass)}>
            {navbarProps && (
              <div className={css(navbarWrapperClass)}>
                <Navbar {...navbarProps} />
              </div>
            )}
            {!hideModal && <Modal />}
            <div className={css(contentContainerClass)}>
              {!hideBanner && <Banner {...this.props} />}
              {children}
            </div>
          </div>
        )}
      </ClassNames>
    )
  }
}

const containerCss = css`
  background-color: ${lightTheme.colors.white};
`

const fullHeightContainerCss = css`
  height: 100%;
`

const navbarWrapperCss = css`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
`

const navbarWithBanner = css`
  ${navbarWrapperCss};
  top: 4.8rem;
`

const fullHeightNavbarWrapperCss = css`
  z-index: 2;
`

const contentContainerCss = css`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media (max-width: ${breakpoint.max.lg}px) {
    margin-top: 56px;
  }
`

const fullHeightContentContainerCss = css`
  ${contentContainerCss};
  margin-top: 0;

  @media (max-width: ${breakpoint.max.lg}px) {
    margin-top: 0;
    height: calc(100% - 56px);
  }
`
